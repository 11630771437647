<template>
  <div class="row no-gutters" ref="targetForm">
    <div class="col-lg-8 col-md-7 order-md-first d-flex align-items-stretch">
      <div class="contact-wrap w-100 p-md-5 p-4">
        <h2 class="animate__animated animate__rollIn mb-4 fw-bold" style="color:#002169;">Contacto</h2>
        <div id="form-message-warning" class="mb-4"></div>

        <form @submit.prevent="submitForm" id="contact-form" name="contactForm" class="contactForm"
          novalidate="novalidate">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" id="nombre" name="nombre" v-model="nombre" required class="form-control"
                  placeholder="Nombre" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="email" id="email" name="email" v-model="email" required class="form-control"
                  placeholder="Correo electrónico" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="tel" id="telefono" name="telefono" v-model="telefono" required class="form-control"
                  placeholder="Teléfono" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <select id="servicioInteres" v-model="servicioInteres" required class="form-select"
                  :disabled="typeService != ''">
                  <option v-for="(servicio, id) in servicios" :key="id" :value="servicio.id"
                    :disabled="servicio.disable" :selected="servicio.selected">
                    {{ servicio.description }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <textarea id="mensaje" name="mensaje" v-model="mensaje" rows="7" required class="form-control"
                  placeholder="Comentario o mensaje"></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group" style="margin-left: 75%;">
                <button type="submit" :disabled="sendContact" class="btn text-white" style="background-color: #002169;">
                  Enviar Mensaje
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-lg-4 col-md-5 d-flex align-items-stretch">
      <div class="info-wrap p-md-4 p-3">
        <h2 class="mb-2 fw-bold">CONTÁCTANOS</h2>
        <ul class="list-unstyled">
          <li class="mb-2 py-1 d-flex align-items-start">
            <img src="../assets/iconos/ubicacion azul.svg" alt="" class="me-2">
            <span>Perif. Paseo de la República No. 1600, <br>El Mirador del Punhuato.
              CP. 58249, Morelia, Michoacán.</span>
          </li>
          <li class="mb-2 py-1 d-flex align-items-start">
            <img src="../assets/iconos/tel azul.svg" alt="" class="me-2">
            <a href="tel:+524432750223" class="text-decoration-none text-reset">
              <span class="">+52 4432750223</span>
            </a>
          </li>

          <li class="mb-2 py-1 d-flex align-items-start">
            <img src="../assets/iconos/ubicacion azul.svg" alt="" class="me-2">
            <span>San Jerónimo #111, <br>Col. La Florida, León, Gto.</span>
          </li>
          <li class="mb-2 py-1 d-flex align-items-start">
            <img src="../assets/iconos/tel azul.svg" alt="" class="me-2">
            <a href="tel:+524775609752" class="text-decoration-none text-reset">
              <span class="pt-2">+52 4775609752</span>
              <span class=""> o </span>
            </a>

            <a href="tel:+524775609753" class="text-decoration-none text-reset">
              <span class="px-2">+52 4775609753</span>
            </a>
          </li>
          <li class="mb-2 py-1 d-flex align-items-start">
            <img src="../assets/iconos/maiil azul.svg" alt="" class="me-2">
            <a href="mailto:contacto@sip.coop" class="text-decoration-none text-reset">
              <span class="text-highlight">contacto@sip.coop</span>
            </a>
          </li>
          <li class="mb-2 py-1 d-flex align-items-start">
            <img src="../assets/iconos/horario azul.svg" alt="" class="me-2">
            <span class="text-highlight">Horario: Lunes a viernes de 8:00 am a 6:00 pm</span>
          </li>
          <li class="mb-2 py-2">
            <br>
            <a href="https://www.facebook.com/soluciones.integrales.de.personal" target=""
              class="text-highlight align-items-center px-1">
              <img src="../assets/iconos/face azul.svg" alt="" height="20px" width="30px">
            </a>
            <a href="https://wa.me/4432725058" class="text-highlight align-items-center px-1">
              <img src="../assets/iconos/whats azul.svg" alt="" height="20px" width="30px">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormContactComponent',
  props: {
    typeService: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      nombre: '',
      email: '',
      telefono: '',
      mensaje: '',
      servicioInteres: this.typeService,
      // Lista de servicios
      servicios: [
        { id: "", description: 'Seleccione su servicio de interés', selected: true, disable: true },
        { id: "Guardia", description: 'Guardias', selected: false, disable: false },
        { id: "Intendencia", description: 'Intendencia', selected: false, disable: false },
      ],
      sendContact: false,
    }
  },
  methods: {
    async submitForm() {
      try {
        if(this.sendContact)return;
        this.sendContact = true; // Deshabilitar el botón de envío para evitar múltiples envíos
        const response = await fetch('/backend/contacto.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            nombre: this.nombre,
            email: this.email,
            telefono: this.telefono,
            mensaje: this.mensaje,
            servicioInteres: this.servicioInteres
          })
        });
        const result = await response.json();

        if (response.ok) {
          alert(result.msg);
          // Limpiar los campos del formulario
          this.nombre = '';
          this.email = '';
          this.telefono = '';
          this.mensaje = '';
        } else {
          alert('Error al enviar el formulario: ' + result.msg);
        }
        this.sendContact = false; // Rehabilitar el botón de envío
      } catch (error) {
        console.error('Error:', error);
        alert('Error al enviar el formulario');
        this.sendContact = false; // Rehabilitar el botón de envío
      }
    },
    scrollToTargetForm() {
      const target = this.$refs.targetForm;
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    },
  }
}
</script>

<style scoped>
.contact-wrap {
  max-width: 100%;
}

ul {
  padding: 1%;
  list-style: none;
}

.info-wrap {
  color: #002169;
  border-radius: 3%;
  background-color: #f8f8f8;
  text-align: justify;
  font-style: normal;
}

.icon i {
  font-size: 1.5rem;

}

.text a {
  color: inherit;
  text-decoration: none;

}

.text a:hover {
  color: inherit;
  text-decoration: underline;
}

.btn {
  background-color: #002169;
}

.form-group {
  margin-bottom: 1.5rem;

}
</style>