<template>
    <footer class="footer text-center text-lg-start">
        <!-- Grid container -->
        <div class="container p-4">
            <!--Grid row-->
            <div class="row my-4">
                <!--Grid column-->
                <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                    <div class="">
                        <img src="../assets/logo sip blanco.svg" height="80" alt="" loading="lazy" />
                    </div>
                </div>

                <!--Grid column-->
                <div class="col-lg-3 col-md-6 mb-4" style="text-align: justify;">
                    <h5 class="text-uppercase mb-3 text-highlight">Datos de Contacto</h5>
                    <ul class="list-unstyled">
                        <li class="mb-2 py-1 d-flex align-items-center">
                            <img src="../assets/iconos/UBICACION AMARILLO.svg" alt="" class="me-2 pb-5">
                            <span class="text-white"> Perif. Paseo de la República No. 1600, <br>El Mirador del
                                Punhuato.
                                <br> CP. 58249, Morelia, Michoacán.</span>
                        </li>
                        <li class="mb-2 py-1 d-flex align-items-center gap-2">
                            <!-- <img src="../assets/iconos/telefono amarillo.svg" alt="" class="me-2"> -->
                            <i class="bi bi-telephone text-highlight"></i>
                            <a href="tel:+524432750223" class="text-decoration-none text-white">
                                <span class="">+52 4432750223</span>
                            </a>
                        </li>
                        <li class="mb-2 py-1 d-flex align-items-center">
                            <img src="../assets/iconos/UBICACION AMARILLO.svg" alt="" class="me-2 pb-3">
                            <span class="text-white"> San Jerónimo #111, <br>Col. La Florida, León, Gto.</span>
                        </li>
                        <!-- <li class="mb-2 py-1 d-flex align-items-center gap-2">
                            <i class="bi bi-telephone text-highlight"></i>
                            <a href="tel:+524775609752" class="text-decoration-none text-reset d-flex align-items-center">
                                <span class="text-white">+52 4775609752</span>
                                
                            </a>
                            <a href="tel:+524775609753" class="text-decoration-none text-reset ">
                                <span class="px-1 text-white">o</span>
                                <span class="px-1 text-white">+52 4775609753</span>
                            </a>
                        </li> -->
                        <li class="mb-2 py-1 d-flex align-items-center gap-2">
                            <i class="bi bi-telephone text-highlight"></i>
                            <div>
                                <a href="tel:+524775609752" class="text-decoration-none text-reset d-flex align-items-center">
                                <span class="text-white">+52 4775609752</span>
                                
                            </a>
                            <span class="px-1 text-white">ó</span>
                            <a href="tel:+524775609753" class="text-decoration-none text-reset ">
                                <span class="px-1 text-white">+52 4775609753</span>
                            </a>
                            </div>
                        </li>
                        <li class="mb-2 py-1 d-flex align-items-center">
                            <img src="../assets/iconos/mail amarillo.svg " alt="" class="me-2">
                            <a href="mailto:contacto@sip.coop" class="text-decoration-none text-reset">
                                <span class="text-white">contacto@sip.coop</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-3 col-md-6 mb-5" style="text-align: justify;">
                    <h5 class="text-uppercase mb-3 text-highlight">Mapa del Sitio</h5>
                    <ul class="list text-highlight">
                        <li class="mb-2">
                            <a href="/" class="text-white">Inicio</a>
                        </li>
                        <li class="mb-2">
                            <a href="/nosotros" class="text-white">Nosotros</a>
                        </li>
                        <li class="mb-2">
                            <a href="/guardias" class="text-white">Guardias</a>
                        </li>
                        <li class="mb-2">
                            <a href="/intendencia" class="text-white">Intendencia</a>
                        </li>

                        <li class="mb-2">
                            <a href="/contacto" class="text-white">Contacto</a>
                        </li>
                        <li class="mb-2">
                            <a href="/documentos/aviso de privacidad.pdf" class="text-white">Aviso de Privacidad</a>
                        </li>
                        <li class="mb-2">
                            <a href="http://191.101.165.110/ERP/Sip/" class="text-white">Sistema de Atención</a>
                        </li>
                        <li class="mb-2">
                            <a href="/documentos/cv.pdf" class="text-white">Curriculum Empresarial</a>
                        </li>
                    </ul>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-3 col-md-6 mb-5" style="text-align: justify;">

                    <h5 class="text-uppercase mb-5 text-highlight">Síguenos
                        <br>
                        <a href="https://www.facebook.com/soluciones.integrales.de.personal">
                            <img src="../assets/iconos/face amarillo.svg" alt="" height="20px">
                        </a>
                    </h5>

                    <ul class="list-unstyled ">
                        <li>
                            <span class="text-highlight fw-bold">Permiso de Operación Federal</span>
                            <p class="text-white">(DGSP-236-15/2968)</p>
                        </li>
                        <li>
                            <br>
                            <span class="text-highlight fw-bold">Permiso de Operación Estatal</span>
                            <p class="text-white ">Guanajuato (3.20.III.10.22)</p>
                            <p class="text-white">Michoacán (SSPSP-151170215)</p>
                        </li>

                    </ul>
                </div>
                <!--Grid column-->
            </div>
            <!--Grid row-->
            <p class="text-white mb-0 d-flex justify-content-center">© 2024 Sinóptica</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent'
};
</script>

<style scoped>
.footer {
    background: linear-gradient(to right, #2CC2ED, #002169, #002169, #002169);
}

.list {
    list-style-type: circle;
    /* Cambia el marcador a un círculo vacío */
    padding-left: 20px;
    /* Agrega espacio para alinear los marcadores */
}

.list a {
    text-decoration: none;
    /* Asegura que los enlaces no tengan subrayado */
    color: white;
    /* Mantiene el texto blanco */
}

.text-highlight {

    color: #FFC844;
}

/* Media query para móviles */
@media (max-width: 768px) {
    .footer {
        background: #002169;
        /* Cambia a un color sólido */
    }
}
</style>
